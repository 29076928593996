<template>
  <div>
    <van-nav-bar title="标题" left-text="返回" right-text="确认" left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight">
    </van-nav-bar>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      row-key="id"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      @selection-change="handleSelectionChange"
      @select="handleSelect">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div style="margin-left: 20px;">
            <el-form label-position="left" label-width="80px" inline class="demo-table-expand">
              <el-form-item label="订单时间">
                <span>{{ props.row.orderTime }}</span>
              </el-form-item>
              <el-form-item label="用户名称">
                <span>{{ props.row.realName }}</span>
              </el-form-item>
              <el-form-item label="手机号码">
                <span>{{ props.row.userPhone }}</span>
              </el-form-item>
              <el-form-item label="商品数量">
                <span>{{ props.row.totalNum }}</span>
              </el-form-item>
              <el-form-item label="总额">
                <span>{{ props.row.totalPrice }}</span>
              </el-form-item>
              <el-form-item label="已支付金额">
                <span>{{ props.row.payPrice }}</span>
              </el-form-item>
            </el-form>

            <div style="margin-left: 5px;">
              <div v-for="(item, index) in props.row.orderSelectVos" :key="index" :name="index">
                <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
                             @change="handleCheckAllChange($event, item)">
                  {{ item.storeName }}
                </el-checkbox>
                <el-checkbox-group v-model="item.checkedList" @change="handleCheckedCitiesChange($event, item)">
                  <el-checkbox v-for="(subI) in item.storeProductAttrValues" :label="subI.sku"
                               :key="subI.sku">{{ subI.sku + ':' + subI.number + subI.sUnitName}}
                  </el-checkbox>
                </el-checkbox-group>
                <div style="margin: 15px 0;"></div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="id"
        label="订单号"
        width="120">
      </el-table-column>
      <el-table-column
        label="日期"
        width="120"
        show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.orderTime }}</template>
      </el-table-column>
      <el-table-column
        prop="realName"
        label="姓名"
        width="120">
      </el-table-column>
      <el-table-column
        prop="totalPrice"
        label="金额"
        show-overflow-tooltip>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  AddressList,
  Button,
  Card,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Col,
  ContactCard,
  CouponCell,
  DatetimePicker,
  Dialog,
  Divider,
  Empty,
  Field,
  Form,
  Icon,
  Image as VanImage,
  Image,
  ImagePreview,
  List,
  Loading,
  NavBar,
  Notify,
  Popup,
  PullRefresh,
  Row,
  Sidebar,
  SidebarItem,
  Step,
  Stepper,
  Steps,
  Sticky,
  SubmitBar,
  Tab,
  Tabs,
  Tag,
  Toast,
  Uploader
} from 'vant';

import * as adminOrderService from "@/api/admin/order";
import {getAccountOrder4SelectList} from "@/api/admin/order";
import $store from "@/store";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [ContactCard.name]: ContactCard,
    [Tag.name]: Tag,
    [VanImage.name]: VanImage,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [Checkbox.name]: Checkbox,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [List.name]: List,
    [CheckboxGroup.name]: CheckboxGroup,
    [Image.name]: Image,
    [Notify.name]: Notify,
    [CouponCell.name]: CouponCell,
    [AddressList.name]: AddressList,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Empty.name]: Empty,
    [NavBar.name]: NavBar
  },
  name: "DebtInfo",
  data() {
    return {
      selectedData: [],
      debtChildren: [],
      debt: {},
      timelines: [],
      timelineActive: 0,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      searchParam: {
        storeName: '',
        sku: '',
        orderBy: 'orderTime'
      },
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      // checkAll: false,
      // checkedCities: ['上海', '北京'],
      // cities: cityOptions,
      // isIndeterminate: true,
      tableData: [],
      multipleSelection: []
    };
  },
  created() {
    // if (this.$route.params && this.$route.params.debtId) {
    //   this.initData()
    // }
    if (this.$route.params.userId !== undefined && this.$route.params.userId !== 'undefined' && this.$route.params.userId !== 'null' && this.$route.params.userId !== null) {
      this.searchParam.userId = this.$route.params.userId
      this.onLoad()
    }
  },
  methods: {
    handleCheckAllChange(val, item) {
      console.log('handleCheckAllChange', val, item)

      item.checkedList = val ? item.skuList : [];
      item.isIndeterminate = false;
      this.handleCheckedCitiesChange(item.skuList, item)
    },
    handleCheckedCitiesChange(value, item) {
      let checkedCount = value.length;
      item.checkAll = checkedCount === item.storeProductAttrValues.length;
      item.isIndeterminate = checkedCount > 0 && checkedCount < item.storeProductAttrValues.length;
      console.log('handleCheckedCitiesChange', value, item)

      const checkedOID = item.storeProductAttrValues[0].oid + ''
      console.log('checkedOID', checkedOID)

      let data = this.selectedData.find(i => i.productId === item.productId)
      console.log('handleCheckedCitiesChange data', data)
      if (value.length < 1 && data === null) {
        return
      }
      if (value.length < 1 && data !== null) {
        data.storeProductAttrValues.forEach((su, index, arr) => {
          let oidL = su.checkedOid.split(',')
          const oidHave = oidL.includes(checkedOID)
          console.log('su.checkedOid oidHave', oidHave)
          if (oidHave) {
            let sIData = item.storeProductAttrValues.find(si => si.sku === su.sku)
            su.number = Number(su.number) - Number(sIData.number)
            console.log('su.checkedOid', su.number)
            oidL = oidL.filter(i => i !== checkedOID)
            su.checkedOid = oidL.join(',')
          }

          if (Number(su.number) < 1) {
            arr.splice(index,1)
          }
        })
        if (data.storeProductAttrValues.length < 1) {
          this.selectedData = this.selectedData.filter(i => i.productId !== data.productId)
        }
        console.log('aaa', JSON.stringify(this.selectedData))
        return
      }

      let attrL = []
      value.forEach(v => {
        console.log('handleCheckedCitiesChange v', v)
        let sIData = item.storeProductAttrValues.find(si => si.sku === v)
        console.log('handleCheckedCitiesChange sIData', sIData)
        if (sIData !== null) {
          sIData.checkedOid = sIData.oid + ''
          attrL.push({...sIData})
        }
      })
      console.log('handleCheckedCitiesChange attrL', attrL)

      if (data === null || data === undefined) {
        const newD = {
          id: item.productId,
          productId: item.productId,
          storeName: item.storeName,
          image: item.storeProductAttrValues[0].image,
          storeProductAttrValues: attrL
        }
        this.selectedData.push(newD)
      } else {
        // 增加sku
        attrL.forEach(v => {
          let subS = data.storeProductAttrValues.find(si => si.sku === v.sku)
          console.log('attrL.forEach v', v)
          console.log('attrL.forEach subS', subS)
          if (subS !== null && subS !== undefined) {
            console.log('attrL.forEach v.number', v.number)
            console.log('attrL.forEach subS.number', subS.number)
            // sku所属订单号数组
            let oidL = subS.checkedOid.split(',')
            console.log('attrL.forEach oidL', oidL)
            const oidHave = oidL.includes(v.checkedOid)
            if (!oidHave) {
              // 新订单选择的
              subS.number = Number(v.number) + Number(subS.number)
              // 将新oid加入checkedOid
              oidL.push(v.oid)
              subS.checkedOid = oidL.join(',')
            }
            console.log('attrL.forEach subS.number', subS.number)
            console.log('attrL.forEach subS.checkedOid', subS.checkedOid)
          } else {
            data.storeProductAttrValues.push(v)
          }
        })

        data.storeProductAttrValues.forEach((i, index, arr) => {
          let subS = attrL.find(si => si.sku === i.sku)
          if (subS !== null && subS !== undefined) {

          } else {
            let oidL = i.checkedOid.split(',')
            const oidHave = oidL.includes(checkedOID)
            console.log('su.checkedOid oidHave', oidHave)
            if (oidHave) {
              let sIData = item.storeProductAttrValues.find(si => si.sku === i.sku)
              i.number = Number(i.number) - Number(sIData.number)
              console.log('su.checkedOid', i.number)
              oidL = oidL.filter(i => i !== checkedOID)
              i.checkedOid = oidL.join(',')
            }
            if (Number(i.number) < 1) {
              arr.splice(index,1)
            }
          }
        })
      }
      console.log('aaa', JSON.stringify(this.selectedData))
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log('handleSelectionChange', this.multipleSelection)
    },
    handleSelect(selection, row) {
      console.log('handleSelect')
      console.log(selection)
      console.log(row)
    },
    onLoad() {
      console.log('onload')
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      let that = this;
      adminOrderService.getAccountOrder4SelectList(this.searchParam).then(res => {
        if (res.status === 200) {
          this.tableData = res.data
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          console.log(this.pages)
          // if (that.pages.currPage > that.pages.pageCount) {
          //   that.finished = true;
          // }

          console.log(that.dataList)
        }
      });
    },
    onMerge() {
      this.$router.push({path: '/order/debt/merge/' + this.debt.userId + '/' + this.debt.id})
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.selectedData.forEach(i => {
        i.storeProductAttrValues.sort((a, b) => a.sku - b.sku)
      })
      console.log('onClickRight', this.selectedData)
      $store.commit('SET_PRODUCTS', this.selectedData)
      this.selectedData = []
      // this.onRefresh()
      this.$router.push({path: '/return'});
    },
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}

.order-tag-margin-left {
  margin-left: 2px
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
